import * as API from "@/services/API";

export default {
  getList(name) {
    return API.apiClient.get(`/directories?name=${name}`);
  },
  getAll() {
    return API.apiClient.get(`/directories`);
  },
};
