import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const genderRoutes = [
  {
    path: "/directories/gender",
    name: "GenderList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "gender" */ "./components/List"),
  },
  //{
  //  path: "/directories/gender/:id",
  //  name: "GenderView",
  //  meta: { middleware: [auth, admin] },
  //  component: () =>
  //    import(/* webpackChunkName: "gender.id" */ "./components/View"),
  //  hidden: true,
  //},
];
