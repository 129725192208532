import { getError } from "@/utils/helpers";
import RoleService from "@/services/RoleService";

export const namespaced = true;

/*function setPaginatedRoles(commit, response) {
  commit("SET_ROLES", response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}*/
function setAll(commit, data) {
  state.all = data;
  commit("SET_ALL", state.all);
}

export const state = {
  items: [],
  all: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_ROLES(state, items) {
    state.items = items;
  },
  SET_ALL(state, all) {
    state.all = all;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async ["Loader"]({ commit }) {
    if (!state.all || state.all.length == 0) {
      await RoleService.getAll()
        .then((response) => {
          setAll(commit, response.data.data);
        })
        .catch((error) => {
          commit("SET_ERROR", getError(error));
        });
    }
  },
  async ["lazyLoader"]({ commit }) {
    if (!state.all || state.all.length == 0) {
      commit("SET_LOADING", true);
      await RoleService.getAll()
        .then((response) => {
          setAll(commit, response.data.data);
        })
        .catch((error) => {
          commit("SET_ERROR", getError(error));
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    }
  },
  async ["Clean"]({ commit }) {
    setAll(commit, []);
  },
};

export const getters = {
  all: (state) => {
    return state.all;
  },
  loading: (state) => {
    return state.loading;
  },
};
