import { getError } from "@/utils/helpers";
import DirectoryService from "@/services/DirectoryService";

export const namespaced = true;

function setDirectory(commit, name, data) {
  state.items[name] = data;
  commit("SET_DIRECTORY", state.items);
}

export const state = {
  items: [],
  loading: false,
  loadAll: false,
  loadingAll: false,
  error: null,
};

export const mutations = {
  SET_DIRECTORY(state, items) {
    state.items = items;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_LOAD_ALL(state, action) {
    state.loadAll = action;
  },
  SET_LOADING_ALL(state, action) {
    state.loadingAll = action;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async ["Loader"]({ commit }, name = "") {
    if (!state.items[name] || state.items[name].length == 0) {
      await DirectoryService.getList(name)
        .then((response) => {
          setDirectory(commit, name, response.data.data);
        })
        .catch((error) => {
          commit("SET_ERROR", getError(error));
        });
    }
  },
  async ["allLoader"]({ commit }) {
    if (!state.loadingAll && !state.loadAll) {
      commit("SET_LOADING_ALL", true);
      await DirectoryService.getAll()
        .then((response) => {
          Object.entries(response.data.data).map((arr) => {
            if (!state.items[arr[0]] || state.items[arr[0]].length == 0) {
              setDirectory(commit, arr[0], arr[1]);
            }
          });
        })
        .catch((error) => {
          commit("SET_ERROR", getError(error));
        })
        .finally(() => {
          commit("SET_LOADING_ALL", false);
          commit("SET_LOAD_ALL", true);
        });
    }
  },
  async ["lazyLoader"]({ commit }, name = "") {
    if (!state.items[name] || state.items[name].length == 0) {
      commit("SET_LOADING", true);
      await DirectoryService.getList(name)
        .then((response) => {
          setDirectory(commit, name, response.data.data);
        })
        .catch((error) => {
          commit("SET_ERROR", getError(error));
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    }
  },
  async ["Clean"]({ commit }, name = "") {
    setDirectory(commit, name, []);
  },
};

export const getters = {
  items: (state) => {
    return state.items;
  },
  loading: (state) => {
    return state.loading;
  },
  loadAll: (state) => {
    return state.loadAll;
  },
  loadingAll: (state) => {
    return state.loadingAll;
  },
};
