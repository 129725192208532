import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const medicineSingledoseUnitRoutes = [
  {
    path: "/directories/medicine-singledose-unit",
    name: "MedicineSingledoseUnitList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(
        /* webpackChunkName: "medicineSingledoseUnit" */ "./components/List"
      ),
  },
];
