import { createApp } from "vue";
import dayjs from "dayjs";
import store from "./store";
import router from "./router";
import globalMixin from "./core/mixins/globalMixin";
import "./assets/css/tailwind.css";
import ElementPlus from "element-plus";
import locale from "element-plus/lib/locale/lang/ru";
import "./assets/css/element-plus.scss";
import App from "./App.vue";

const app = createApp(App);

app
  .provide("dayjs", dayjs)
  .mixin(globalMixin)
  .use(ElementPlus, { locale })
  .use(router)
  .use(store)
  .mount("#app");
