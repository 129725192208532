import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const medicineActionsRoutes = [
  {
    path: "/directories/medicine-actions",
    name: "MedicineActionsList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "medicineActions" */ "./components/List"),
  },
];
