import * as API from "@/services/API";

const API_ENDPOINT = "/adverse-reaction";

export default {
  fetch(data) {
    return API.apiClient.get(API_ENDPOINT, { params: data });
  },

  find(id) {
    return API.apiClient.get(API_ENDPOINT + "/" + id);
  },

  create(model) {
    return API.apiClient.post(API_ENDPOINT, model);
  },

  update(model) {
    return API.apiClient.put(API_ENDPOINT + "/" + model.id, model);
  },

  delete(id) {
    return API.apiClient.delete(API_ENDPOINT + "/" + id);
  },

  history(id) {
    return API.apiClient.get(API_ENDPOINT + "/" + id + "/history");
  },

  lastInformation() {
    return API.apiClient.get("/users/information");
  },

  exportExcel(ids) {
    return API.apiClient.get("/export" + API_ENDPOINT, {
      params: { id: ids },
      responseType: "blob",
    });
  },
};
