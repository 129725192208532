import * as types from "./types";

export default {
  [types.USE_OBTAIN](state, items) {
    state.items = items;
  },
  [types.USE_CLEAR](state) {
    state.items = [];
  },
  [types.USE_SET_LOADING](state, loading) {
    state.itemsLoading = loading;
  },
  [types.USE_META](state, meta) {
    state.itemsMeta = meta;
  },
};
