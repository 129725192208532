import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const medicineIndicationRoutes = [
  {
    path: "/directories/medicine-indication",
    name: "MedicineIndicationList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "medicineIndication" */ "./components/List"),
  },
];
