// Module Name
export const USE_NAME = `UndesiredLang`;

// Actions
export const USE_FETCH = `[${USE_NAME}] Fetch`;

// Mutations
export const USE_META = `[${USE_NAME}] meta`;
export const USE_OBTAIN = `[${USE_NAME}] obtain`;
export const USE_CLEAR = `[${USE_NAME}] clear`;
export const USE_SET_LOADING = `[${USE_NAME}] set loading`;
export const USE_ERROR = `[${USE_NAME}] error`;

export default {};
