import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const studyNameRoutes = [
  {
    path: "/directories/study-name",
    name: "StudyNameList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "studyName" */ "./components/List"),
  },
];
