import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const patientRoutes = [
  {
    path: "/directories/patient",
    name: "PatientList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "patient" */ "./components/List"),
  },
  //{
  //  path: "/directories/patient/:id",
  //  name: "PatientView",
  //  meta: { middleware: [auth, admin] },
  //  component: () =>
  //    import(/* webpackChunkName: "patient.id" */ "./components/View"),
  //  hidden: true,
  //},
];
