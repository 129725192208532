import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const undesiredDescriptionRoutes = [
  {
    path: "/directories/undesired-description",
    name: "UndesiredDescriptionList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(
        /* webpackChunkName: "undesiredDescription" */ "./components/List"
      ),
  },
];
