import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const undesiredExodusRoutes = [
  {
    path: "/directories/undesired-exodus",
    name: "UndesiredExodusList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "undesiredExodus" */ "./components/List"),
  },
];
