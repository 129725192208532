import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const studyTypeRoutes = [
  {
    path: "/directories/study-type",
    name: "StudyTypeList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "studyType" */ "./components/List"),
  },
];
