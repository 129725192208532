import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const unitRoutes = [
  {
    path: "/directories/unit",
    name: "UnitList",
    meta: { middleware: [auth, admin] },
    component: () => import(/* webpackChunkName: "unit" */ "./components/List"),
  },
];
