import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const medicineManufacturerRoutes = [
  {
    path: "/directories/medicine-manufacturer",
    name: "MedicineManufacturerList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(
        /* webpackChunkName: "medicineManufacturer" */ "./components/List"
      ),
  },
];
