import auth from "@/middleware/auth";
import { adverseReactionRoutes } from "../modules/adverseReaction/routes";

export const userRoutes = [
  ...adverseReactionRoutes,
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { middleware: [auth] },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { middleware: [auth] },
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile"),
  },
  //{
  //  path: "/adverse-reaction/:id",
  //  name: "AdverseReactionEdit",
  //  meta: { middleware: [auth] },
  //  component: () =>
  //    import(/* webpackChunkName: "adverse-reaction.id" */ "@/components/AdverseReactionEdit"),
  //  hidden: true,
  //},
];
