import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const medicineDurationUnitRoutes = [
  {
    path: "/directories/medicine-duration-unit",
    name: "MedicineDurationUnitList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(
        /* webpackChunkName: "medicineDurationUnit" */ "./components/List"
      ),
  },
];
