//import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";
import guest from "@/middleware/guest";
import middlewarePipeline from "@/router/middlewarePipeline";
import { adminRoutes } from "./admin";
import { authRoutes } from "./auth";
import { userRoutes } from "./user";

//Vue.use(VueRouter);

const routes = [
  ...adminRoutes,
  ...authRoutes,
  ...userRoutes,
  {
    path: "/",
    name: "Home",
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: "home" */ "../views/Home"),
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound"),
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
