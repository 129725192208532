<template>
  <div id="app" class="common-layout">
    <el-container>
      <el-header><NavMenu mode="horizontal" /></el-header>
      <el-main><router-view /></el-main>
    </el-container>
    <!--Header />
    <main class="container mx-auto">
      <router-view />
    </main-->
  </div>
</template>

<script>
import NavMenu from "@/components/NavMenu";

export default {
  name: "App",
  components: {
    //Header,
    NavMenu,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

#app {
  font-family: Open Sans, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

.el-header {
  background-color: var(--el-color-primary-dark-2);
}
</style>
