import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const usersRoutes = [
  {
    path: "/users",
    name: "UsersList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "users" */ "./components/List"),
  },
  {
    path: "/users/:id",
    name: "UsersView",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "users.id" */ "./components/View"),
    hidden: true,
  },
];
