import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const medicineIndicationOtherRoutes = [
  {
    path: "/directories/medicine-indication-other",
    name: "MedicineIndicationOtherList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(
        /* webpackChunkName: "medicineIndicationOther" */ "./components/List"
      ),
  },
];
