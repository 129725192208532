export const DATETIME_DB_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_DB_FORMAT = "YYYY-MM-DD";

export const DATETIME_FORMAT = "DD/MM/YYYY HH:mm";
export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm";

export const DATE_PIKER_FORMAT = "dd/MM/yyyy";
export const MONTH_PIKER_FORMAT = "MMM yyyy";
export const DATATIME_PIKER_FORMAT = "dd/MM/yyyy HH:mm";
