import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    items: [],
    itemsMeta: [],
    itemsLoading: true,
  },
  getters,
  mutations,
  actions,
};
