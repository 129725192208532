import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const undesiredLangRoutes = [
  {
    path: "/directories/undesired-lang",
    name: "UndesiredLangList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "undesiredLang" */ "./components/List"),
  },
];
