<template>
  <!--el-menu :default-active="$router.path"-->
  <el-menu
    :router="true"
    :mode="mode"
    background-color="var(--el-color-primary-dark-2)"
    text-color="#fff"
    active-text-color="#ffd04b"
    :ellipsis="authUser ? true : false"
  >
    <el-menu-item
      v-if="authUser"
      index="Dashboard"
      :route="{ name: 'Dashboard' }"
    >
      LOGO
    </el-menu-item>
    <el-menu-item v-else index="Home" :route="{ name: 'Home' }">
      LOGO
    </el-menu-item>
    <el-menu-item
      v-if="authUser"
      index="AdverseReactionList"
      :route="{ name: 'AdverseReactionList' }"
    >
      Реестр НР
    </el-menu-item>
    <el-menu-item
      v-if="isAdmin"
      index="UsersList"
      :route="{ name: 'UsersList' }"
    >
      Пользователи
    </el-menu-item>
    <el-sub-menu v-if="isAdmin" index="directories">
      <template #title>Справочники</template>
      <el-sub-menu index="directories-medicines">
        <template #title>Сведения о ЛС</template>
        <el-menu-item
          index="MedicineRoleList"
          :route="{ name: 'MedicineRoleList' }"
        >
          Роль препарата
        </el-menu-item>
        <el-menu-item
          index="MedicineMedicineList"
          :route="{ name: 'MedicineMedicineList' }"
        >
          Лекарственное средство
        </el-menu-item>
        <el-menu-item
          index="MedicineTnList"
          :route="{ name: 'MedicineTnList' }"
        >
          ТН
        </el-menu-item>
        <el-menu-item
          index="MedicineMnnList"
          :route="{ name: 'MedicineMnnList' }"
        >
          МНН
        </el-menu-item>
        <el-menu-item
          index="MedicineManufacturerList"
          :route="{ name: 'MedicineManufacturerList' }"
        >
          Производитель
        </el-menu-item>
        <el-menu-item
          index="MedicineSingledoseUnitList"
          :route="{ name: 'MedicineSingledoseUnitList' }"
        >
          Разовая доза
        </el-menu-item>
        <el-menu-item
          index="MedicineReceptionUnitList"
          :route="{ name: 'MedicineReceptionUnitList' }"
        >
          Кратность приема
        </el-menu-item>
        <el-menu-item
          index="MedicineDateList"
          :route="{ name: 'MedicineDateList' }"
        >
          Дата приема
        </el-menu-item>
        <el-menu-item
          index="MedicineDurationUnitList"
          :route="{ name: 'MedicineDurationUnitList' }"
        >
          Продолжительность
        </el-menu-item>
        <el-menu-item
          index="MedicineDosageFormList"
          :route="{ name: 'MedicineDosageFormList' }"
        >
          Лекарственная форма
        </el-menu-item>
        <el-menu-item
          index="MedicineLeadingList"
          :route="{ name: 'MedicineLeadingList' }"
        >
          Путь введения
        </el-menu-item>
        <el-menu-item
          index="MedicineIndicationList"
          :route="{ name: 'MedicineIndicationList' }"
        >
          Показание
        </el-menu-item>
        <el-menu-item
          index="MedicineIndicationOtherList"
          :route="{ name: 'MedicineIndicationOtherList' }"
        >
          Показание другие
        </el-menu-item>
        <el-menu-item
          index="MedicineActionsList"
          :route="{ name: 'MedicineActionsList' }"
        >
          Действия с препаратом
        </el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="directories-undesireds">
        <template #title>Сведения о НР</template>
        <el-menu-item
          index="UndesiredDescriptionList"
          :route="{ name: 'UndesiredDescriptionList' }"
        >
          Описание НР
        </el-menu-item>
        <el-menu-item
          index="UndesiredSeverityList"
          :route="{ name: 'UndesiredSeverityList' }"
        >
          Критерии серьёзности
        </el-menu-item>
        <el-menu-item
          index="UndesiredExodusList"
          :route="{ name: 'UndesiredExodusList' }"
        >
          Исход
        </el-menu-item>
        <el-menu-item
          index="UndesiredLangList"
          :route="{ name: 'UndesiredLangList' }"
        >
          Язык оригинала
        </el-menu-item>
      </el-sub-menu>
      <el-menu-item
        index="MessageTypeList"
        :route="{ name: 'MessageTypeList' }"
      >
        Тип сообщения
      </el-menu-item>
      <el-menu-item index="SenderTypeList" :route="{ name: 'SenderTypeList' }">
        Тип отправителя
      </el-menu-item>
      <el-menu-item
        index="AddressTypeList"
        :route="{ name: 'AddressTypeList' }"
      >
        Тип адреса
      </el-menu-item>
      <el-menu-item index="StudyTypeList" :route="{ name: 'StudyTypeList' }">
        Тип исследования
      </el-menu-item>
      <el-menu-item
        index="StudyNumberList"
        :route="{ name: 'StudyNumberList' }"
      >
        Номер исследования
      </el-menu-item>
      <el-menu-item index="StudyNameList" :route="{ name: 'StudyNameList' }">
        Наименование исследования
      </el-menu-item>
      <el-menu-item index="PatientList" :route="{ name: 'PatientList' }">
        Пациент
      </el-menu-item>
      <el-menu-item index="TreatmentList" :route="{ name: 'TreatmentList' }">
        Лечение
      </el-menu-item>
      <el-menu-item index="AgegroupList" :route="{ name: 'AgegroupList' }">
        Возрастная группа
      </el-menu-item>
      <el-menu-item index="AllergyList" :route="{ name: 'AllergyList' }">
        Аллергия
      </el-menu-item>
      <el-menu-item index="UnitList" :route="{ name: 'UnitList' }">
        Единицы измерения
      </el-menu-item>
      <el-menu-item index="GenderList" :route="{ name: 'GenderList' }">
        Пол
      </el-menu-item>
      <el-menu-item index="CountryList" :route="{ name: 'CountryList' }">
        Страна
      </el-menu-item>
      <el-menu-item index="RegionList" :route="{ name: 'RegionList' }">
        Регион
      </el-menu-item>
    </el-sub-menu>
    <div class="flex-grow" />
    <el-menu-item v-if="authUser" index="profile" :route="{ path: '/profile' }">
      {{ authUser.name }}
    </el-menu-item>
    <el-menu-item v-if="authUser">
      <Logout />
    </el-menu-item>
    <el-menu-item v-else index="login" :route="{ path: '/login' }">
      Login
    </el-menu-item>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";
import Logout from "@/components/Logout";

export default {
  name: "NavMenu",
  components: {
    Logout,
  },
  props: {
    mode: {
      type: String,
      default: "horizontal",
    },
  },
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser", "isAdmin"]),
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style>
.flex-grow {
  flex-grow: 1;
}

.el-menu--horizontal {
  border-bottom: 0;
}
.el-dropdown {
  font-size: inherit !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}
.el-icon-arrow-down {
  font-size: 13px;
}
</style>
