import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const addressTypeRoutes = [
  {
    path: "/directories/addresstype",
    name: "AddressTypeList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "addressType" */ "./components/List"),
  },
];
