import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const medicineMnnRoutes = [
  {
    path: "/directories/medicine-mnn",
    name: "MedicineMnnList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "medicineMnn" */ "./components/List"),
  },
];
