import auth from "@/middleware/auth";

export const adverseReactionRoutes = [
  {
    path: "/adverse-reaction",
    name: "AdverseReactionList",
    meta: { middleware: [auth] },
    component: () =>
      import(/* webpackChunkName: "adverse-reaction" */ "./components/List"),
  },
  {
    path: "/adverse-reaction/:id",
    name: "AdverseReactionView",
    meta: { middleware: [auth] },
    component: () =>
      import(/* webpackChunkName: "adverse-reaction.id" */ "./components/View"),
    hidden: true,
  },
  //{
  //  path: "/adverse-reaction/test",
  //  name: "AdverseReactionViewTest",
  //  meta: { middleware: [auth] },
  //  component: () =>
  //    import(
  //      /* webpackChunkName: "adverse-reaction.id" */ "./components/ViewTest"
  //    ),
  //  hidden: true,
  //},
];
