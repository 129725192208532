import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const studyNumberRoutes = [
  {
    path: "/directories/study-number",
    name: "StudyNumberList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "studyNumber" */ "./components/List"),
  },
];
