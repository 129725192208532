import * as API from "@/services/API";

const API_ENDPOINT = "/directories/region";

export default {
  fetch(data) {
    return API.apiClient.get(API_ENDPOINT, { params: data });
  },

  find(id) {
    return API.apiClient.get(API_ENDPOINT + "/" + id);
  },

  create(model) {
    return API.apiClient.post(API_ENDPOINT, model);
  },

  update(model) {
    return API.apiClient.put(API_ENDPOINT + "/" + model.id, model);
  },

  delete(id) {
    return API.apiClient.delete(API_ENDPOINT + "/" + id);
  },
};
