import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const medicineReceptionUnitRoutes = [
  {
    path: "/directories/medicine-reception-unit",
    name: "MedicineReceptionUnitList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(
        /* webpackChunkName: "medicineReceptionUnit" */ "./components/List"
      ),
  },
];
