import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const medicineDosageFormRoutes = [
  {
    path: "/directories/medicine-dosage-form",
    name: "MedicineDosageFormList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "medicineDosageForm" */ "./components/List"),
  },
];
