import * as types from "./types";
import service from "../services";

export default {
  async [types.USE_FETCH]({ commit }, data = null) {
    commit(types.USE_SET_LOADING, true);
    const response = await service.fetch(data);
    commit(types.USE_OBTAIN, response.data.data);
    commit(types.USE_META, response.data.meta);
    commit(types.USE_SET_LOADING, false);
  },
};
