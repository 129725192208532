import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const medicineTnRoutes = [
  {
    path: "/directories/medicine-tn",
    name: "MedicineTnList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "medicineTn" */ "./components/List"),
  },
];
