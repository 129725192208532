<template>
  <button
    type="button"
    class="inline-flex bg-transparent items-center space-x-2"
    @click="logout"
  >
    <span class="hidden sm:inline">Logout</span>
    <!--LogoutIcon class="w-6 h-6 text-white" /-->
  </button>
</template>

<script>
//import LogoutIcon from "@/components/icons/LogoutIcon";

export default {
  name: "Logout",
  components: {
    //LogoutIcon,
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>
