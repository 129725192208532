import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const messageTypeRoutes = [
  {
    path: "/directories/message-type",
    name: "MessageTypeList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "messageType" */ "./components/List"),
  },
  //{
  //  path: "/directories/message-type/:id",
  //  name: "MessageTypeView",
  //  meta: { middleware: [auth, admin] },
  //  component: () =>
  //    import(/* webpackChunkName: "messageType.id" */ "./components/View"),
  //  hidden: true,
  //},
];
